import React, { useState, useEffect, useCallback } from 'react';
import './App.css';

// Import the McDonald's Wojak image
const mcdonaldsWojakImage = `${process.env.PUBLIC_URL}/mcdonalds-wojak.png`;
const backgroundMobileImage = `${process.env.PUBLIC_URL}/background-mobile.png`;


function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [copied, setCopied] = useState(false);
  const [showIframe, setShowIframe] = useState(false); // Add state for showing/hiding the iframe


  const eye1Image = `${process.env.PUBLIC_URL}/dexscreener.png`;
  const noseImage = `${process.env.PUBLIC_URL}/raydium-removebg-preview.png`;
  const telegramImage = `${process.env.PUBLIC_URL}/telegramlogo.png`;
  const xImage = `${process.env.PUBLIC_URL}/xlogo.png`;

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 600);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText('GRodG7Lh7QzThPMGXnNW4EvE2QuZyNPfFktbKLvnpump');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, []);

  const openJobApplication = () => {
    window.open('https://jobs.mchire.com/', '_blank');
  };

  return (
    <div className="App">
      <div
        className="background-container"
        style={{ backgroundImage: `url(${isMobile ? backgroundMobileImage : mcdonaldsWojakImage})` }}
      />
      {isMobile && <h1 className="mobile-title">$McJak</h1>}
      <div className="icons-bar">
        <a href="https://dexscreener.com/solana/GRodG7Lh7QzThPMGXnNW4EvE2QuZyNPfFktbKLvnpump" className="icon" target="_blank" rel="noopener noreferrer">
          <img src={eye1Image} alt="Dexscreener Logo" className="icon-image" />
        </a>
        <a href="https://raydium.io/swap/?outputMint=GRodG7Lh7QzThPMGXnNW4EvE2QuZyNPfFktbKLvnpump&inputMint=sol" className="icon" target="_blank" rel="noopener noreferrer">
          <img src={noseImage} alt="Nose Logo" className="icon-image" />
        </a>
        <a href="https://t.co/SbD8DxSB1v" className="icon" target="_blank" rel="noopener noreferrer">
          <img src={telegramImage} alt="Telegram Logo" className="icon-image" />
        </a>
        <a href="https://x.com/mcdonaldsjak" className="icon" target="_blank" rel="noopener noreferrer">
          <img src={xImage} alt="X Logo" className="icon-image" />
        </a>
      </div>
      <div className="content">
      <button className="mcdonalds-application-button" onClick={openJobApplication}>
          Apply to McDonald's
        </button>
        <footer className="App-footer" onClick={handleCopy}>
          <div className="image3-container">
            <p className="contract-address">CA: GRodG7Lh7QzThPMGXnNW4EvE2QuZyNPfFktbKLvnpump</p>
            {copied && <span className="copied-message">Copied!</span>}
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;